import store from "../store";
import admin_routes from "./admin";
import user_routes from "./users";

const routes = [
  {
    path: "/",
    component: () => import("../app/layout/users/Layout.vue"),
    children: user_routes,
    beforeEnter: async (to, from, next) => {
      if (store.state.loggedIn) {
        store
          .dispatch("refreshUserData")
          .then((user) => {
            if (user.role_id === 1 || user.role_id === 3) { // 1 = admin, 3 = moderator
              next("/admin");
            } else {
              if (user.verified) {
                if (user.status === 1) {
                  next();
                } else {
                  next("/verify_account");
                }
              } else {
                next("/verify_email");
              }
            }
          })
          .catch((err) => {
            store.dispatch("logout");
            next("/login");
          });
      } else {
        store.dispatch("logout");
        next("/login");
      }
    },
  },
  {
    path: "/admin/",
    component: () => import("../app/layout/admin/Layout.vue"),
    children: admin_routes,
    beforeEnter: async (to, from, next) => {
      if (store.state.loggedIn) {
        store
          .dispatch("refreshUserData")
          .then((user) => {
            if (user.role_id === 2) {
              next("/");
            } else {
              next();
            }
          })
          .catch((err) => {
            store.dispatch("logout");
            next("/login");
          });
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/verify_email",
    name: "verify_email",
    component: () => import("../app/public/VerifyEmail.vue"),
    meta: {
      title: "Verify email",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.loggedIn) {
        store
          .dispatch("refreshUserData")
          .then((user) => {
            if (user.verified) {
              if (user.role_id === 1 || user.role_id === 3) {
                next("/admin");
              } else {
                if (user.status === 1) {
                  next("/");
                } else {
                  next("/verify_account");
                }
              }
            } else {
              next();
            }
          })
          .catch((err) => {
            store.dispatch("logout");
            next("/login");
          });
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/verify_account",
    name: "verify_account",
    component: () => import("../app/public/VerifyAccount.vue"),
    meta: {
      title: "Verify Account",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.loggedIn) {
        store
          .dispatch("refreshUserData")
          .then((user) => {
            if (user.verified) {
              if (user.role_id === 1 || user.role_id === 3) {
                next("/admin");
              } else {
                if (user.status === 1) {
                  next("/");
                } else {
                  next();
                }
              }
            } else {
              next("/verify_email");
            }
          })
          .catch((err) => {
            store.dispatch("logout");
            next("/login");
          });
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../app/public/Login.vue"),
    meta: {
      title: "Login",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.loggedIn) {
        store
          .dispatch("refreshUserData")
          .then((user) => {
            if (user.verified) {
              if (user.role_id === 1 || user.role_id === 3) {
                next("/admin");
              } else {
                if (user.status === 1) {
                  next();
                } else {
                  next("/verify_account");
                }
              }
            } else {
              next("/verify_email");
            }
          })
          .catch((err) => {
            store.dispatch("logout");
            next("/login");
          });
      } else {
        next();
      }
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../app/public/register/register.vue"),
    meta: {
      title: "Register",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.loggedIn) {
        store
          .dispatch("refreshUserData")
          .then((user) => {
            if (user.verified) {
              if (user.role_id === 1 || user.role_id === 3) {
                next("/admin");
              } else {
                if (user.status === 1) {
                  next();
                } else {
                  next("/verify_account");
                }
              }
            } else {
              next("/verify_email");
            }
          })
          .catch((err) => {
            store.dispatch("logout");
            next("/login");
          });
      } else {
        next();
      }
    },
  },
  {
    path: "/forgot_password",
    name: "forgot_password",
    component: () => import("../app/public/ForgotPass.vue"),
    meta: {
      title: "Forgot password",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.loggedIn) {
        store
          .dispatch("refreshUserData")
          .then((user) => {
            if (user.verified) {
              if (user.role_id === 1 || user.role_id === 3) {
                next("/admin");
              } else {
                if (user.status === 1) {
                  next();
                } else {
                  next("/verify_account");
                }
              }
            } else {
              next("/verify_email");
            }
          })
          .catch((err) => {
            store.dispatch("logout");
            next("/login");
          });
      } else {
        next();
      }
    },
  },
  {
    path: "/reset_password",
    name: "reset_password",
    component: () => import("../app/public/ResetPass.vue"),
    meta: {
      title: "Reset password",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.loggedIn) {
        store
          .dispatch("refreshUserData")
          .then((user) => {
            if (user.verified) {
              if (user.role_id === 1 || user.role_id === 3) {
                next("/admin");
              } else {
                if (user.status === 1) {
                  next();
                } else {
                  next("/verify_account");
                }
              }
            } else {
              next("/verify_email");
            }
          })
          .catch((err) => {
            store.dispatch("logout");
            next("/login");
          });
      } else {
        let user = JSON.parse(localStorage.getItem("forgot_user"));
        if (user && user.token) {
          next();
        } else {
          next("/forgot_password");
        }
      }
    },
  },
  {
    path: "/terms_of_use",
    name: "terms_of_use",
    component: () => import("../app/public/TermsOfUse.vue"),
    meta: {
      title: "Terms Of Use",
    },
  },
  {
    path: "/privacy_policy",
    name: "privacy_policy",
    component: () => import("../app/public/PrivacyPolicy.vue"),
    meta: {
      title: "Privacy Policy",
    },
  },
  {
    path: "/downloads",
    name: "downloads",
    component: () => import("../app/public/Downloads.vue"),
    meta: {
      title: "Downloads",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.loggedIn) {
        next();
      } else {
        store.dispatch("refreshSiteSettings").then((settings) => {
          if (settings.downloads_public) {
            next();
          } else {
            next("/login");
          }
        });
      }
    },
  },
  {
    path: "/price-list",
    name: "price_list_public",
    component: () => import("../app/public/PriceList.vue"),
    meta: {
      title: "Price List"
    }
  },
  {
    path: "/shop",
    name: "shop",
    component: () => import("../app/users/shop/shop-home/shop-home.vue"),
    meta: {
      title: "Home | Shop",
    },
  },
  {
    path: "/shop/product/:slug",
    name: "product",
    component: () => import("../app/users/shop/view-product/view-product.vue"),
  },
  {
    path: "/shop/terms-and-conditions",
    name: "terms_and_conditions",
    component: () => import("../app/users/shop/terms-and-conditions/TermsAndConditions.vue"),
    meta: {
      title: "Terms & Conditions | Shop",
    }
  },
  {
    path: "/dealers-locator",
    name: "dealers_locator",
    component: () => import("../app/public/DealersLocator.vue"),
    meta: {
      title: "Dealers Locator",
    }
  },
  {
    path: "/500",
    name: "500",
    component: () => import("../app/public/500.vue"),
    meta: {
      title: "500",
    },
  },
  {
    path: "*",
    name: "*",
    component: () => import("../app/public/404.vue"),
    meta: {
      title: "400",
    },
  },
];

export default routes;
